`use strict`;

import '../../../../node_modules/owl.carousel/dist/owl.carousel.min.js';

export default class OwlCarouselLib {
    init() {
        $('.owl-carousel').owlCarousel({
            items: 1,
            lazyLoad: true,
            loop: true,
            autoplay: true,
            autoplayTimeout: 5000,
            animateOut: 'fadeOut',
            nav:true,
            dots: false,
            navText : [
                "<i class='fa fa-chevron-left'></i>",
                "<i class='fa fa-chevron-right'></i>"
            ]
        });
    }
}
